*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html,
body,
#__next,
#root {
  height: 100%;
  height: -webkit-fill-available;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --color: #d4d4d4;
}

body {
  background-color: var(--color-background);
  color: var(--color-foreground);
}

.node-hover {
  background: #ededec;
}

.view-line {
  box-shadow: 1px 1px 0px var(--color);
}

.monaco-editor .line-numbers {
  color: #838383 !important;
}

.monaco-editor,
.monaco-editor-background,
.monaco-editor .inputarea.ime-input {
  background: none !important;
}

.errorMessage {
  padding: 1rem;
  background-color: #ededec;
  font-size: 1rem;
}

.errorWrapper {
  display: grid;
  height: 100%;
  place-content: center;
}

.errorWrapper > div {
  display: grid;
  gap: 1rem;
}

.errorWrapper button {
  border: 1px solid #b6b6b6;
  font-family: inherit;
  font-size: inherit;
  padding: 1rem;
  border-radius: 2px;
}
